import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Location} from '@reach/router';
import {GatsbyImage} from 'gatsby-plugin-image';

import styles from './navbar.module.scss';

export class PureNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
    collapsed: !this.state.collapsed
    });
  }

  renderNavbar(screen_size) {
    const active_link_style =  {textShadow: '-0.5px -0.5px 0.7px'};

    const nav_links = [
      {
        label: 'home',
        to: '/',
        id: 'home'
      },
      {
        label: 'what we do',
        to: '/services/',
        id: 'services'
      },
      {
        label: 'about',
        to: '/about/',
        id: 'about'
      },
      {
        label: 'careers',
        to: '/careers/',
        id: 'careers'
      }
    ];

    if (screen_size == 'small') {
      const link_list = nav_links.map((link, index) => {
        if (index == nav_links.length - 1) {
          return (
            <li className={styles.listItem} key={link.label}>
              <Link
                className={styles.hamburgerLink}
                to={link.to}
                activeStyle={active_link_style}
                id={`small-${link.id}`}
                onClick={() => this.setState({collapsed: true})}
              >{link.label}

              </Link>
            </li>
          );
        }
        return (
          <li className={styles.listItem} key={link.label}>
            <Link
              className={styles.hamburgerLink}
              to={link.to}
              activeStyle={active_link_style}
              id={`small-${link.id}`}
              onClick={() => this.setState({collapsed: true})}
            >
              {link.label}
            </Link>
          </li>
        );
      });
      return link_list;
    }

    const large_link_list = nav_links.map((link) => {
      return (
        <li className={styles.listItem} key={link.label}>
          <Link className={styles.link} tabIndex={1} to={link.to} activeStyle={active_link_style} id={`large-${link.id}`}>{link.label}</Link>
        </li>
      );
    });
    return large_link_list;
  }

  render() {
    const collapsed = this.state.collapsed;
    let small_overlay = collapsed ?  'd-none' : styles.overlay;

    return (
      <nav className={styles.nav} role='navigation'>
        <div className={'row' + ' ' + styles.mainNavRow} >
          {/* full size nav bar logo*/}
          <Link className={'d-none d-md-flex' + ' ' + styles.textLogo} to='/' id="text-logo" tabIndex={1}>
            <GatsbyImage
              image={this.props.data.largeLogo.childImageSharp.gatsbyImageData}
              alt='csnw logo with company name'
            />
          </Link>

          {/* hamburger nav bar toggler*/}
          <button
            className={styles.hamburgerIcon + ' ' + 'd-block d-md-none'}
            type='button'
            onClick={() => this.toggleNavbar()}
            aria-controls='expanded-nav' aria-expanded='false'
            aria-label='Toggle navigation'
            id="hamburger-icon"
          >
            <FontAwesomeIcon icon={this.state.collapsed ? ['fal', 'bars'] : ['fal', 'times']}
            />
          </button>

          {/* small screen nav bar logo*/}
          <Link
            className={'d-flex d-md-none row' + ' ' + styles.plainLogo}
            to='/'
            id="plain-logo"
            onClick={() => this.setState({collapsed: true})}
          >
            <GatsbyImage
              image={this.props.data.smallLogo.childImageSharp.gatsbyImageData}
              alt='csnw logo'
            />
          </Link>

          {/* full size nav bar links*/}
          <ul className={styles.links + ' ' + 'd-none d-md-block'} data-testid='links-lg'>
            {this.renderNavbar('large')}
          </ul >
        </div>

        {/* hamburger nav bar links*/}
        <div className={'col d-md-none' + ' ' + `${small_overlay}`} data-testid='links-sm'>
          <ul
            className={styles.hamburgerLinksMenu}
            id="expanded-nav"
          >
            {this.renderNavbar('small')}
          </ul >
        </div>
      </nav>
    );
  }
}

export const Navbar = props => (
  <StaticQuery
    query={graphql`{
  smallLogo: file(relativePath: {eq: "images/csnw-logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 65, height: 60, placeholder: NONE, layout: FIXED)
    }
  }
  largeLogo: file(relativePath: {eq: "images/csnw-logo-text.png"}) {
    childImageSharp {
      gatsbyImageData(width: 260, height: 60, placeholder: NONE, layout: FIXED)
    }
  }
}
`}
    render={
      data =>
        <Location>
          {locationProps => <PureNavbar {...locationProps} data={data}/>}
        </Location>
    }
  />
);

export default Navbar;