import React from 'react';
import {Helmet} from 'react-helmet';
import {graphql, StaticQuery} from 'gatsby';

import favicon from '../../assets/images/csnw-logo.png';

const SEO = ({description, title}) => {
  return (
    <StaticQuery
      query={graphql`
        query SEOQuery {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
      render={data => {
        return (
          <Helmet
            title={title || data.site.siteMetadata.title}
            meta={[
                {
                  name: `description`,
                  content: description || data.site.siteMetadata.description
                },
                {
                  property: `og:title`,
                  content: title || data.site.siteMetadata.title
                },
                {
                  property: `og:description`,
                  content: description || data.site.siteMetadata.description
                },
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  property: `keywords`,
                  content: `IT solutions, enterprise applications, infrastructure management, systems administration, data center management, software modernization, data center migration, app development, app management, app development and management, custom IT solutions, scalable IT solutions`
                }
            ]}
            link={[
                {rel: 'shortcut icon', type: 'image/png', href: `${favicon}`}
            ]}
          >
            <html lang="en" />
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;