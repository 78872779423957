import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import styles from './footer.module.scss';

export class PureFooter extends Component {

  getCapacitiesLinks() {
    if (this.props.data.allMarkdownRemark.totalCount == 0) return null;

    const capacities_links = this.props.data.allMarkdownRemark.edges.map(({node}, index) => {
      const {frontmatter} = node;
      const {capacity_title, id} = frontmatter;

      return (
        <li key={id} className={styles.listItem}><Link to={`/services/#${id}`} id={id} className={styles.link}>{capacity_title}</Link></li>
      );
    });
    return capacities_links;
  }

  render() {
    const {data} = this.props;
    const {contact, file, markdownRemark} = data;

    const today = new Date();
    const year = today.getFullYear();

    return (
      <footer className={'row' + ' ' + styles.footer} id="footer">
        <div className={'d-xs-block d-md-none' + ' ' + styles.smallLogoDiv}>
          <GatsbyImage
            image={file.childImageSharp.gatsbyImageData}
            className={styles.logo}
            alt='csnw logo'
          />
        </div>
        <div className={'offset-md-1 offset-lg-2 col-md-4'}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>
              <GatsbyImage
                image={file.childImageSharp.gatsbyImageData}
                className={'d-none d-md-block' + ' ' + styles.logo}
                alt='csnw logo'
              />
            </div>
            <div className={'col-md-11' + ' ' + styles.noPadding}>
              <p className={styles.footerText}>{markdownRemark.frontmatter.tagline}</p>
              <div className={styles.footerText}>
                <p className={styles.copyright}>© {year} {markdownRemark.frontmatter.company_name}</p>
                <div className={'row' + ' ' + styles.contactRow}>
                  <a target="_blank" rel="noopener noreferrer" href={contact.frontmatter.info_href} className={styles.emailLink} id="email-link">{contact.frontmatter.info_email}</a>
                  &nbsp;
                  <a className={styles.link} href={contact.frontmatter.phone_href} id="phone-link">{contact.frontmatter.phone}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'offset-sm-3 offset-md-0 col-xs-12 col-sm-3 col-md-2 col-lg-1' + ' ' + styles.company} aria-label='Company information'>
          <h2 className={styles.listHeader}>{markdownRemark.frontmatter.company_links_title}</h2>
          <ul className={styles.list}>
            <li className={styles.listItem}><Link to='/' id="home" className={styles.link}>Home</Link></li>
            <li className={styles.listItem}><Link to='/about/' id="about" className={styles.link}>About</Link></li>
            <li className={styles.listItem}><Link to='/careers/' id="careers" className={styles.link}>Careers</Link></li>
            <li className={styles.listItem}><Link to='/services/' id="services" className={styles.link}>Services</Link></li>
            <li className={styles.listItem}>
              <a target="_blank" rel="noopener noreferrer" href={contact.frontmatter.info_href} className={styles.link} id="contact-link">Contact</a></li>
          </ul>
        </div>
        <div className={'col-xs-12 col-sm-6 col-md-2'  + ' ' + styles.company} aria-label='List of capacities'>
          <h2 className={styles.listHeader}>{markdownRemark.frontmatter.capacities_links_title}</h2>
          <ul className={styles.list}>
            {this.getCapacitiesLinks()}

          </ul>
        </div>

      </footer>
    );
  }

}

export const Footer = props => (
  <StaticQuery
    query={graphql`{
  file(relativePath: {eq: "images/csnw-logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 32, height: 29, layout: FIXED)
    }
  }
  markdownRemark(frontmatter: {path: {eq: "/footer/"}}) {
    frontmatter {
      tagline
      company_name
      company_links_title
      capacities_links_title
    }
  }
  contact: markdownRemark(frontmatter: {path: {eq: "/contact-info/"}}) {
    frontmatter {
      info_email
      info_href
      phone
      phone_href
    }
  }
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/capacities/"}}
    sort: {fields: frontmatter___order}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          capacity_title
          id
        }
      }
    }
  }
}
`}
    render={data => <PureFooter {...props} data={data} />}
  />
);

export default Footer;