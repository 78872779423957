import {config, library} from '@fortawesome/fontawesome-svg-core';
import {faLongArrowRight as faLongArrowRightRegular} from '@fortawesome/pro-regular-svg-icons';
import {
  faBalanceScale,
  faBars,
  faBookReader,
  faCampfire,
  faChevronLeft,
  faChevronRight,
  faCommentAlt,
  faDoorOpen,
  faElephant,
  faHandHoldingHeart,
  faHeadSideBrain,
  faLongArrowRight,
  faMinus,
  faPlus,
  faShareAlt,
  faSquareFull,
  faSunDust,
  faTimes,
  faUsersClass,
  faWind
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faCompass,
  faHandHoldingSeedling,
  faMountain,
  faRoute,
  faSnowflakes,
  faStarHalfAlt,
  faWater
} from '@fortawesome/pro-duotone-svg-icons';

export default function registerIcons() {
  library.add(
    faBalanceScale,
    faBars,
    faBookReader,
    faCampfire,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCommentAlt,
    faDoorOpen,
    faCampfire,
    faCompass,
    faElephant,
    faHandHoldingHeart,
    faHandHoldingSeedling,
    faHeadSideBrain,
    faLongArrowRight,
    faMinus,
    faMountain,
    faPlus,
    faRoute,
    faShareAlt,
    faSnowflakes,
    faSquareFull,
    faStarHalfAlt,
    faSunDust,
    faTimes,
    faUsersClass,
    faWater,
    faWind,
    faLongArrowRightRegular
  );
}

// to prevent icons from appearing very large on page reloads
config.autoAddCss = false;